// import '../scss/bootstrap/bootstrap.scss';
import '../scss/style.scss';
import 'popper.js';
import 'bootstrap';
import 'share-api-polyfill';
// import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
// import "@fortawesome/fontawesome-pro/scss/solid.scss";
// import "@fortawesome/fontawesome-pro/scss/regular.scss";
// import "@fortawesome/fontawesome-pro/scss/brands.scss";
// require('@fortawesome/fontawesome-free/css/all.min.css');
// require('@fortawesome/fontawesome-pro/css/all.min.css');

// require('@fortawesome/fontawesome-free/js/all.js');
// require('@fortawesome/fontawesome-pro/js/all.js');

require('./vendor/jquery.min.js');
require('./vendor/browser.min.js');
const breakpoints = require('./vendor/breakpoints.min.js');
require('./vendor/jquery.dropotron.min.js');
require('./vendor/bootstrap.bundle.min.js');
require('./vendor/lightbox.min.js');
require('./vendor/util.js');
global.breakpoints = breakpoints;
require('./vendor/main.js');